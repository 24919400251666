<template>
  <div class="aboutus">
    <div class="col-11 col-sm-10 m-auto">
      <div class="aboutus_content d-sm-flex flex-column flex-lg-row">
        <div class="president-content d-flex d-lg-none">
          <h2 class="mb-4">{{ title }}</h2>
        </div>
        <div
          class="p-0 d-lg-flex justify-content-center"
          style="margin: auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <div class="infomation d-flex">
            <div class="content owner">
              <img
                v-lazy="aboutCeo"
                class="img-fluid"
                width="600"
                height="600"
                alt="ceo"
              />
            </div>
          </div>
        </div>

        <div
          class="p-0 p-lg-3"
          style="margin: auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <div class="">
            <div class="container content">
              <div class="row">
                <div
                  class="col-12 mt-0 py-0"
                  style="display: flex; align-items: flex-start; gap: 20px"
                >
                  <div class="president-content">
                    <h2 class="d-none d-lg-flex" style="margin-bottom: 32px">
                      {{ title }}
                    </h2>
                    <p>{{ sub }}</p>
                  </div>
                </div>
                <div class="link-more">
                  <router-link :to="'/about-us'" style="text-decoration: none">
                    <button
                      type="button"
                      class="btn btn-primary text see-all d-flex align-items-center btn-about-us"
                    >
                      {{ btnView }}
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="services__item__detail-img"
                      >
                        <path
                          d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "About",
  data() {
    return {
      aboutCeo: "/images/home/about-ceo.webp",
      title: this.$t("home").about.title,
      sub: this.$t("home").about.sub,
      acthor: this.$t("home").about.acthor,
      btnView: this.$t("home").about.btnView,
    };
  },
  watch: {
    lang() {
      this.title = this.$t("home").about.title;
      this.sub = this.$t("home").about.sub;
      this.acthor = this.$t("home").about.acthor;
      this.btnView = this.$t("home").about.btnView;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.aboutus {
  background: url("/images/home/about_us.webp");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
  padding: 150px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1199.98px) {
    & {
      background: none;
      padding: 0 0 50px;
    }
    .owner {
      margin-bottom: 32px;
    }
    .link-more {
      justify-content: center;
      margin-top: 16px;
    }
  }
  @media (max-width: 767.98px) {
    .aboutus_content {
      padding: 0;
    }
    .owner img {
      width: 293px;
      height: 293px;
    }
    .president-content {
      h2 {
        font-size: 32px !important;
      }
    }
  }
}
.infomation {
  gap: 20px;
}
.aboutus_content {
  padding: 0 10%;
  display: flex;
  gap: 5%;
  justify-content: center;
  align-items: center;
}
.link-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.president-content {
  h2 {
    color: var(--blue-text);
    font-weight: 700;
    font-size: 40px;
  }
  p {
    font-weight: 500;
    font-size: 20px;
    color: var(--grey-text);
  }
}

.btn-about-us {
  background: #fc7735;
  border-radius: 40px;
  padding: 10px 28px;
  width: max-content;
  border: none;
  gap: 8px;
}

.btn-about-us:hover {
  opacity: 0.8;
  background: #fc7735;
  transition: 0.3s;
}

.owner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  span {
    font-size: 18px;
    font-weight: 700;
    color: #184799;
  }
}
</style>
